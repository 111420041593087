<template>
  <div>
    <div class="row">
      <div class="col-lg-4 col-md-6 col-12" v-for="user in  Rsuser.rs" v-bind:key="user.user_key">
        <div class="card card-apply-job">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center mb-1">
              <div class="media">
                <div class="avatar mr-1">
                  <img :src="user.foto" width="42" height="42">
                </div>
                <div class="media-body">
                  <h5 class="mb-0">{{ user.username }}
                  </h5>
                  <small class="text-danger" v-if="user.status_user == 'Non Aktif'"> <i class="fa fa-warning mr-1"></i>{{
                    user.status_user }}</small>
                  <small class="text-info" v-if="user.status_user != 'Non Aktif'"> <i class="fa fa-check mr-1"></i>
                    {{ user.status_user }}</small>
                </div>
              </div>
              <div class="blog-detail-share">
                <b-dropdown variant="link" toggle-class="p-0" no-caret right>
                  <template #button-content>
                    <feather-icon size="21" icon="Share2Icon" class="text-body" />
                  </template>
                  <b-dropdown-item v-b-modal.Edit-user @click="userEdit(user.user_key)" v-if="bidang == '0'">
                    <i class="fa fa-edit mr-1"></i>Edit
                  </b-dropdown-item>
                  <b-dropdown-item v-b-modal.Password-user @click="updatePassword(user.user_key, user.nm_lengkap)">
                    <i class="fa fa-lock mr-1"></i>Update Password
                  </b-dropdown-item>
                  <b-dropdown-item v-if="user.status_user != 'Non Aktif'" @click="enableDisable(user.user_key)">
                    <i class="fa fa-eye-slash mr-1"></i>Disable User
                  </b-dropdown-item>
                  <b-dropdown-item v-if="user.status_user == 'Non Aktif'" @click="enableDisable(user.user_key)">
                    <i class="fa fa-eye mr-1"></i>Enable User
                  </b-dropdown-item>

                  <b-dropdown-item v-if="user.status_user != 'Non Aktif'" @click="deleteUser(user.user_key)">
                    <i class="fa fa-trash mr-1"></i>Delete User
                  </b-dropdown-item>


                </b-dropdown>
              </div>
            </div>
            <h5 class="apply-job-title">{{ user.nm_lengkap }}
              <div class="badge badge-pill badge-light-primary float-right">{{ user.level_name }}</div>
            </h5>
            <small class="text-muted mt-0">email : {{ user.email }}</small><br>
            <small class="text-muted mt-0">{{ user.no_hp }}</small>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <i class="fa fa-list"></i> Total Row {{ Rsuser.lenght }}
      </div>
      <div class="col-lg-6">
        <b-pagination v-model="currentPage" :total-rows="Rsuser.lenght" per-page='6' base-url="#" :align="'right'"
          @click="pageNext(currentPage)" />
      </div>
    </div>


    <EditUSer @get_user=loadPage :userData=userData></EditUSer>
    <PasswordUser :nm_lengkap=nm_lengkap :user_key=user_key></PasswordUser>
  </div>
</template>

<script>
import axios from '@/config/Axios'
import EditUSer from './EditUser';
import Ripple from 'vue-ripple-directive'
import {
  mapMutations,
  mapState
} from 'vuex'

import PasswordUser from './PasswordUser.vue'
import Base from '@/config/Mixins_base';
import {
  BPagination,
  BModal,
  BButton,
  VBModal,
  BDropdown,
  BDropdownItem
} from 'bootstrap-vue'
export default {
  data() {
    return {
      selected: 'default',
      currentPage: 1,
      rows: 0,
      user_key: "",
      userData: {},
      nm_lengkap: ""
    }
  },
  emits: ["get_user"],
  mixins: [Base],
  computed: {
    ...mapState({
      Rsuser: state => state.aclUser.Rsuser,
    })
  },
  props: {
    rs_user: {},
    bidang: {}
  },
  components: {
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,
    BButton,
    VBModal,
    EditUSer,
    PasswordUser,
  },
  mounted() {
    this.rows = this.Rsuser.lenght;
  },
  watch: {
    currentPage(newcurrentPage, oldcurrentPage) {
      if (newcurrentPage) {
        this.loadPage();
      }
    }
  },

  methods: {
    ...mapMutations({
      SetRsUser: 'SetRsUser',
    }),
    get_user() {
      self.$emit("get_user", "");
    },

    userEdit(key) {
      const self = this;
      axios({
        method: 'PUT',
        url: '/api/acl/user/edit',
        data: {
          user_key: key
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.userData = response.data.result

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    updatePassword(userkey, nmlengkap) {
      this.user_key = userkey;
      this.nm_lengkap = nmlengkap;
    },

    enableDisable(key) {
      const self = this;
      axios({
        method: 'POST',
        url: '/api/acl/user/disable',
        data: {
          user_key: key
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.userData = response.data.result
          self.loadPage();

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },

    deleteUser(key) {
      if (confirm('apakah anda yakin akan menghapus data ini') == true) {
        const self = this;
        axios({
          method: 'POST',
          url: '/api/acl/user/delete',
          data: {
            user_key: key
          },
          headers: {
            'Authorization': self.isAuthenticated
          }
        })
          .then(function (response) {
            self.userData = response.data.result
            self.loadPage();

          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      }
    },

    async loadPage() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/acl/user/list',
        data: {
          cari: "",
          offset: self.currentPage
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.SetRsUser(response.data.result);
          self.rs_user = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },

  },

  directives: {
    'b-modal': VBModal,
    Ripple,
  },
}
</script>

<style></style>