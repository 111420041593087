<template>
  <div>
    <div class="row">
      <div class="col-lg-6">

        <div class="form-group ">
          <input type="text" id="payment-card-number" class="form-control" v-model="cari"
            placeholder="search username or nema ">
        </div>
      </div>
      <div class="col-lg-4">
      </div>

      <div class="col-lg-2">
        <b-button class="btn-primary mb-1" block v-b-modal.modal-no-backdrop variant="outline-primary">
          <i class="fa fa-plus"></i>
          Add User
        </b-button>
      </div>
    </div>
    <card-user @get_user=get_user :rs_user=rs_user :bidang=bidang></card-user>
    <FormAdd @get_user=get_user></FormAdd>




  </div>
</template>

<script>
import {
  mapMutations,
  mapState
} from 'vuex'
import Base from '@/config/Mixins_base';
import axios from '../../../config/Axios';
import Ripple from 'vue-ripple-directive'
import {
  BPagination,
  BModal,
  BButton,
  VBModal,


} from 'bootstrap-vue'
import CardUser from './component/CardUser.vue'
import FormAdd from './component/FormAdd.vue'
export default {
  data() {
    return {
      rs_user: [],
      cari: '', bidang: "1"
    }
  },
  mixins: [Base],

  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  computed: {
    ...mapState({
      Rsuser: state => state.aclUser.Rsuser,
    })
  },
  watch: {
    cari(newData, old) {
      if (newData != NaN) {
        this.get_user("1", this.cari);
      }
    }
  },
  components: {
    CardUser,
    FormAdd,
    BPagination,
    BModal,
    BButton,
    VBModal,

  },
  mounted() {
    this.get_user()
    this.cek_bidang();
  },
  methods: {
    ...mapMutations({
      SetRsUser: 'SetRsUser',
    }),
    async cek_bidang() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/acl/user/cek_bidang',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.bidang = response.data.result;
        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async get_user(offset, cari) {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/acl/user/list',
        data: {
          offset: offset,
          cari: cari
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.SetRsUser(response.data.result);
          self.rs_user = response.data.result;
        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
  }
}
</script>

<style></style>